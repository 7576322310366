// Optional: Initialize SimpleMDE if needed
document.addEventListener("DOMContentLoaded", function() {
  const textareas = document.querySelectorAll("textarea[data-simplemde]");
  textareas.forEach(textarea => {
    new SimpleMDE({ element: textarea });
  });

  const flashNotice = document.getElementById('flash-notice');
  const flashAlert = document.getElementById('flash-alert');

  if (flashNotice) {
    alert(flashNotice.dataset.flash);
  }

  if (flashAlert) {
    alert(flashAlert.dataset.flash);
  }

});